import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import Amplify, { API } from 'aws-amplify';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';

import awsmobile from './aws-exports';

registerLocale('es', es);
setDefaultLocale('es', es);

Amplify.configure(awsmobile);

class Registro extends Component {
    state = {
        dob: new Date(),
        today: new Date(),
        email: "",
        name: "",
        lastname: "",
        country: "",
        phone: "SIN-TELEFONO",
        redirect: false        
    };
    
    constructor(props) {
        super(props);
        
        this.post_data = this.post_data.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleDOBChange = this.handleDOBChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleLastnameChange = this.handleLastnameChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
    }

    handleEmailChange(event){
        this.setState({email: event.target.value}); 
    }

    handleNameChange(event){
        this.setState({name: event.target.value}); 
    }

    handleLastnameChange(event){
        this.setState({lastname: event.target.value}); 
    }

    handleCountryChange(event){
        this.setState({country: event.target.value}); 
    }

    handlePhoneChange(event){
        this.setState({phone: event.target.value}); 
    }

    handleDOBChange = date => {
        this.setState({
            dob: date
        });
    };

    handleSubmitClick(event) {
        event.preventDefault();
    }

    post_data(event) {
        API.post('LolyfitActivosAPI', '/registro', {
            body: this.state
        }).then(response => {
            if (response.success){
                this.setState({redirect: true});
            }
        }).catch(error => {
            console.log(error);
        });

        event.preventDefault();
    };
     

    render() {
        const {redirect} = this.state;

        if (redirect){
            return(
                <div className="App" class="container">
                <div className="row mt-2">
                    <div className="col-sm"><img alt="Activos en Casa" src="./activos-en-casa.png" class="img-fluid"/></div>
                    <div className="col-md">
                        <p className="lead text-white bg-dark text-center">Bienvenidos al reto de un mes activos desde casa</p>
                        <p>
                            El reto #ActivosEnCasa es una iniciativa completamente gratuita que tiene como objetivo motivar, no importa donde nos encontremos, a mantenernos activos en casa, aún en situaciones de aislamiento o distanciamiento social como resultado de las acciones contra el Coronavirus. La idea es regalar energía, alegría y movimiento a personas que no puedan salir de sus casas.
                        </p>
                        <p>
                            El reto #ActivosEnCasa se trata de un programa de entrenamiento de tres, cuatro o seis días de ejercicio por semana, según sea la condición de cada persona. No se requiere de ningún equipo adicional para llevar a cabo los ejercicios, solo disposición y buena energía!!!
                        </p>
                    </div>
                </div>
                <div>
                    <br/>
                    <p>Participar es muy sencillo, solo tienes que:</p>
                    <ul>
                        <li>Llenar el siguiente formulario para registrarte en el reto.</li>
                        <li>Después descarga el plan de entrenamiento</li>
                        <li>Seguir el plan de entrenamiento en la guía por cuatro semanas</li>
                        <li>Compartir fotos y/o videos en Instagram Stories y/o Posts de tus entrenamientos usando la etiqueta #ActivosEnCasa para seguir motivando a más personas.</li>
                        <li>Regar la voz a tantas personas como puedas para que cada días más personas se activen y transformemos estos días de aislamiento en un forma de hacernos más fuertes.</li>
                    </ul>
                    <hr className="my-4"/> 
                </div>
                <div className="row mt-2">
                    <div className="card col-md">
                        <a href="./RetoActivosEnCasa.pdf" class="btn btn-primary btn-lg active mt-3" role="button" aria-pressed="true" download="RetoActivosEnCasa.pdf">Descarga la guía</a>
                        <div class="alert alert-warning mt-4 text-center" role="alert">
                            Si tienes alguna pregunta o necesitas ayuda no dudes en contactar a @loly_fit escribiendo a <a href="mailto:info@lolyfit.com">info@lolyfit.com</a>
                        </div>
                   </div>
                </div>
            </div>
            );
        }
        return (
            <div className="App" class="container">
                <div className="row mt-2">
                    <div className="col-sm"><img alt="Activos en Casa" src="./activos-en-casa.png" className="img-fluid"/></div>
                    <div className="col-md">
                        <p className="lead text-white bg-dark text-center">Bienvenidos al reto de un mes activos desde casa</p>
                        <p>
                            El reto #ActivosEnCasa es una iniciativa completamente gratuita que tiene como objetivo motivar, no importa donde nos encontremos, a mantenernos activos en casa, aún en situaciones de aislamiento o distanciamiento social como resultado de las acciones contra el Coronavirus. La idea es regalar energía, alegría y movimiento a personas que no puedan salir de sus casas.
                        </p>
                        <p>
                            El reto #ActivosEnCasa se trata de un programa de entrenamiento de tres, cuatro o seis días de ejercicio por semana, según sea la condición de cada persona. No se requiere de ningún equipo adicional para llevar a cabo los ejercicios, solo disposición y buena energía!!!
                        </p>
                    </div>
                </div>
                <div>
                    <br/>
                    <p>Participar es muy sencillo, solo tienes que:</p>
                    <ul>
                        <li>Llenar el siguiente formulario para registrarte en el reto.</li>
                        <li>Después descarga el plan de entrenamiento</li>
                        <li>Seguir el plan de entrenamiento en la guía por cuatro semanas</li>
                        <li>Compartir fotos y/o videos en Instagram Stories y/o Posts de tus entrenamientos usando la etiqueta #ActivosEnCasa para seguir motivando a más personas.</li>
                        <li>Regar la voz a tantas personas como puedas para que cada días más personas se activen y transformemos estos días de aislamiento en un forma de hacernos más fuertes.</li>
                    </ul>
                    <hr className="my-4"/> 
                </div>
                <div className="row">
                    <div className="card col-md">
                        <form className="needs-validation" onSubmit={this.post_data}>
                            <div className="form-group row mt-2">
                                <div className="col-sm">
                                    <label htmlFor="dataInputEmail">Correo Electrónico</label>
                                    <input type="email" className="form-control" id="dataInputEmail" aria-describedby="emailHelp" placeholder="Correo Electrónico" required onChange={this.handleEmailChange}/>
                                    <small id="emailHelp" className="form-text text-muted">Tu dirección de correo electrónico no será compartida con nadie.</small>
                                    <div className="invalid-feedback">
                                        El correo electrónico es requerido.
                                    </div>
                                    <label htmlFor="dataInputName">Nombre</label>
                                    <input type="text" className="form-control" id="dataInputName" placeholder="Nombre"  required onChange={this.handleNameChange}/>
                                    <div className="invalid-feedback">
                                        El Nombre es requerido.
                                    </div>
                                    <label htmlFor="dataInputLastname">Apellido</label>
                                    <input type="text" className="form-control" id="dataInputLastname" placeholder="Apellido" required onChange={this.handleLastnameChange}/>
                                    <div className="invalid-feedback">
                                        El Apellido es requerido.
                                    </div>
                                    <label htmlFor="dataInputCountry">País de Residencia</label>
                                    <input type="text" className="form-control" id="dataInputCountry" placeholder="País de residencia" required onChange={this.handleCountryChange}/>
                                    <div className="invalid-feedback">
                                        El País de residencia es requerido.
                                    </div>
                                    <label htmlFor="dataInputPhone">Número Telefónico (opcional)</label>
                                    <input type="text" className="form-control" id="dataInputPhone" placeholder="Número Telefónico" onChange={this.handlePhoneChange}/>
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="dataInputDOB">Fecha de Nacimiento</label>
                                    <DatePicker
                                        locale="es"
                                        id="dataInputDOB"
                                        selected={this.state.dob}
                                        onChange={this.handleDOBChange}
                                        maxDate={this.state.today}
                                        className="form-control"
                                        scrollableYearDropdown
                                        showYearDropdown
                                        yearDropdownItemNumber={60}
                                        showMonthDropdown
                                        dateFormat="dd/MM/yyyy"
                                        inline
                                    />
                                </div>
                            </div>
                            <div className="form-group row mt-2">
                                <div className="col">
                               </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Registrarse</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Registro;
